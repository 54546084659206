import Button, { BUTTON_VARIANT } from '@components/button';

import { useConnectWallet } from '@shared/hooks';

const DesktopConnectButton = () => {
  const { handleConnect, isReconnecting } = useConnectWallet();

  return (
    <Button
      variant={BUTTON_VARIANT.PRIMARY}
      className="flex h-10 w-fit shrink-0"
      onClick={handleConnect}
      disabled={isReconnecting}
    >
      Login / Signup
    </Button>
  );
};

export default DesktopConnectButton;
