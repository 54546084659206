import { ErrorResponse } from '@interfaces';
import { IDepositBody, IDepositResponse } from '@interfaces/buyAitech/deposit';
import { useMutation } from '@tanstack/react-query';

import { nowPaymentsApi } from '../nowPaymentsApi';

interface MutationParams {
  onSuccess: (response: IDepositResponse) => void;
  onError: (error: ErrorResponse | Error) => void;
}

const useCreateDepositMutation = ({ onSuccess, onError }: MutationParams) => {
  return useMutation({
    mutationFn: async (body: IDepositBody) =>
      nowPaymentsApi.client.createDeposit(body),
    onSuccess,
    onError,
  });
};

export default useCreateDepositMutation;
