'use client';

import { useState } from 'react';

import { UserInfo } from '@interfaces';
import { twMerge } from 'tailwind-merge';
import { useAccount } from 'wagmi';

import UserActionsDropdown from '@components/profileActionsDropdown/UserActionsDropdown';

import Auth from '@features/auth';
import RegisterSuccessModal from '@features/auth/register/registerSuccessModal';

import DesktopConnectButton from './DesktopConnectButton';
import useWatchAccount from './useWatchAccount';

interface IProps {
  className?: string;
  innerClassName?: string;
  userInfo?: UserInfo;
  isPending: boolean;
}

const ConnectButton = ({
  className,
  innerClassName,
  userInfo,
  isPending,
}: IProps) => {
  const { address } = useAccount();
  const [isSignupSuccessModalOpen, setIsSignupSuccessModalOpen] =
    useState(false);

  useWatchAccount();

  return (
    <>
      <div className={className}>
        {userInfo ? (
          <UserActionsDropdown />
        ) : (
          <div className={twMerge('md:pr-4', innerClassName)}>
            <DesktopConnectButton />
            {/* <MobileConnectButton /> removed after niraj requirement*/}
          </div>
        )}
      </div>
      {address && !isPending && !userInfo && (
        <Auth onSignUp={() => setIsSignupSuccessModalOpen(true)} />
      )}

      <RegisterSuccessModal
        isModalOpen={isSignupSuccessModalOpen}
        handleModalClose={() => setIsSignupSuccessModalOpen(false)}
      />
    </>
  );
};

export default ConnectButton;
