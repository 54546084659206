import { useQuery } from '@tanstack/react-query';

import { FIAT_CURRENCIES } from '@shared/constants/queryKeys';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useGetFiatCurrenciesQuery = () =>
  useQuery({
    queryKey: [FIAT_CURRENCIES],
    queryFn: () => nowPaymentsApi.client.getFiatCurrencies(),
  });

export default useGetFiatCurrenciesQuery;
