import { ICurrencyAmounts } from '@interfaces';

import Button from '@components/button';
import TransactionDetails from '@components/transactionDetails';

import { CheckCircle } from '@shared/assets';
import { formatNullableAmountWithCurrency } from '@shared/utils';

interface IProps {
  currencyAmounts: ICurrencyAmounts;
  handleClose: () => void;
  currencyCode: string;
}

const CompleteFiatPaymentStep = ({
  handleClose,
  currencyCode,
  currencyAmounts: { youPayAmount, youGetAmount, usdaiAmount },
}: IProps) => {
  const parsedYouPay = formatNullableAmountWithCurrency(
    youPayAmount,
    currencyCode
  );
  const parsedYouGet = formatNullableAmountWithCurrency(youGetAmount, 'AITECH');
  const parsedUsdaiAmount = formatNullableAmountWithCurrency(
    usdaiAmount,
    'USDAI'
  );

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <div className="flexCenter roundedBlTr size-8 border border-neutral700 bg-neutral900">
          <CheckCircle className="size-4 text-green500" />
        </div>
        <p>
          <span className="mr-5">1.</span>
          Successful payment creation!
        </p>
      </div>

      <TransactionDetails
        firstPlaceholder="You pay"
        firstValue={parsedYouPay}
        secondPlaceholder="You get"
        secondValue={parsedYouGet}
        secondSubValue={parsedUsdaiAmount}
      />

      {/* TODO gelo ce maknut iz dizajna procesing stateove, pa mozda bude trebalo updateat ovaj dio */}
      <div className="flex items-center gap-4">
        <div className="flexCenter roundedBlTr size-8 border border-neutral700 bg-neutral900">
          <CheckCircle className="size-4 text-green500" />
        </div>
        <p>
          <span className="mr-5">2.</span>
          Payment processed successfully.
        </p>
      </div>
      <hr className="my-6 border-neutral700" />
      <Button className="ml-auto" onClick={handleClose}>
        Finish
      </Button>
    </div>
  );
};

export default CompleteFiatPaymentStep;
