import { useEffect } from 'react';

import { EstimateParams } from '@interfaces/buyAitech/estimate';
import { useQuery } from '@tanstack/react-query';

import { CURRENCY_AITECH } from '@features/marketplace/buyAitech/constants';

import { ESTIMATE_RATE_AITECH } from '@shared/constants/queryKeys';
import { toastError } from '@shared/utils';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useGetEstimateRates = (params: EstimateParams, enabled: boolean) => {
  const { data, error, isError, refetch, isFetching } = useQuery({
    queryKey: [ESTIMATE_RATE_AITECH, ...Object.values(params)],
    queryFn: async () => {
      const { data: estimates } = await nowPaymentsApi.client.getEstimateRate({
        ...params,
        currencyTo: CURRENCY_AITECH,
      });

      return {
        aitechEstimate: estimates.estimatedAmount,
        usdaiEstimate: estimates.usdaiAmount,
      };
    },
    refetchInterval: 20000,
    enabled,
  });

  useEffect(() => {
    if (!isError || !error) return;

    toastError('Error', { description: error?.message });
  }, [error, isError]);

  return {
    data,
    refetch,
    isError,
    isFetching,
  };
};

export default useGetEstimateRates;
