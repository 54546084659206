import { ReactNode } from 'react';

import {
  Coins, // CreditCard
} from '@shared/assets';

export enum E_PAYMENT_METHOD_OPTION_VALUE {
  CARD = 'card',
  TOKEN = 'token',
}

export interface IPaymentMethodOption {
  label: string;
  value: E_PAYMENT_METHOD_OPTION_VALUE;
  icon: ReactNode;
}

export const PAYMENT_METHOD_OPTIONS: IPaymentMethodOption[] = [
  // {
  //   label: 'Visa/ Master Cards/ Credit Cards',
  //   value: E_PAYMENT_METHOD_OPTION_VALUE.CARD,
  //   icon: <CreditCard />,
  // },
  {
    label: 'Tokens/ Coins',
    value: E_PAYMENT_METHOD_OPTION_VALUE.TOKEN,
    icon: <Coins />,
  },
];
