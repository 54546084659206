import Image from 'next/image';

import { ICurrencyAmounts, INowPaymentsData } from '@interfaces';

import ModalFooter from '@components/modals/ModalFooter';
import TransactionDetails from '@components/transactionDetails';

import { CheckCircle, WarningIcon } from '@shared/assets';
import { formatNullableAmountWithCurrency } from '@shared/utils';

interface IProps {
  handleClose: () => void;
  currencyAmounts: ICurrencyAmounts;
  currencyCode: string;
  nowPaymentsData: INowPaymentsData;
}

const CryptoPaymentSuccess = ({
  handleClose,
  currencyAmounts: { youPayAmount, youGetAmount, usdaiAmount },
  currencyCode,
  nowPaymentsData: { qrCode, address },
}: IProps) => {
  const parsedYouPay = formatNullableAmountWithCurrency(
    youPayAmount,
    currencyCode
  );
  const parsedYouGet = formatNullableAmountWithCurrency(youGetAmount, 'AITECH');
  const parsedUsdaiAmount = formatNullableAmountWithCurrency(
    usdaiAmount,
    'USDAI'
  );

  return (
    <div className="space-y-6">
      <div className="flexCenter roundedBlTr size-20 border border-neutral700 bg-neutral900">
        <CheckCircle className="size-7 text-green500" />
      </div>

      <div className="flex flex-col gap-2">
        <p className="font-xxlarge font-bold">Successful payment creation!</p>
        <p className="!font-normal font-medium text-neutral200">
          Use the information below to complete the AI TECH purchase process!
        </p>
      </div>

      <TransactionDetails
        firstPlaceholder="You pay"
        firstValue={parsedYouPay}
        secondPlaceholder="You get"
        secondValue={parsedYouGet}
        secondSubValue={parsedUsdaiAmount}
        thirdPlaceholder="Wallet Address"
        thirdValue={address}
      />
      <p className="font-large mx-auto text-center">Or scan this QR code</p>

      <Image
        alt="qrCode"
        src={qrCode}
        width={142}
        height={142}
        className="mx-auto"
      />

      <div className="flex items-start gap-2 bg-yellow900 p-2 text-yellow500">
        <WarningIcon className="mt-0.5 flex max-h-4 min-h-4 min-w-4 max-w-4" />
        <p className="font-small font-medium">
          Please save this payment information on your device if you close the
          pop-up.
        </p>
      </div>

      <ModalFooter
        confirmButtonProps={{
          text: 'Finish',
          onClick: handleClose,
        }}
      />
    </div>
  );
};

export default CryptoPaymentSuccess;
