import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import BuyAitechModal from '@features/marketplace/buyAitech/BuyAitechModal';

interface IProps {
  className?: string;
  buttonClassName?: string;
}

const BuyAitechButton = ({ className, buttonClassName }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={className}>
      <div>
        <button
          className={twMerge(
            'animatedBorderButton label-small roundedBlTr flexCenter relative z-[1] h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold text-white',
            isOpen ? 'continueAnimation' : '',
            buttonClassName
          )}
          onClick={() => setIsOpen(true)}
        >
          Buy AITECH
        </button>
      </div>

      {isOpen && <BuyAitechModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </div>
  );
};

export default BuyAitechButton;
