'use client';

import { usePathname } from 'next/navigation';

import { twMerge } from 'tailwind-merge';

import useCurrentUser from '@api/user/auth/queries/useCurrentUser';

import Banners from '@components/banners';
import ConnectButton from '@components/connectButton';
import Search from '@components/search';
import ToastMaintenance from '@components/toast/ToastMaintenance';

import ProductNavActions from '@features/admin/ProductManagement/ProductNavActions/ProductNavActions';
import SubmissionNavActions from '@features/admin/ProductSubmissions/SubmissionNavActions/SubmissionNavActions';
import NotificationsButton from '@features/forum/notificationsButton/NotificationsButton';
import BuyAitechButton from '@features/marketplace/buyAitech/BuyAitechButton';
import { useDepositModal } from '@features/profile/layout/profileStats/depositFundsModal/provider/context';

import { LogoSvg } from '@shared/assets';
import config from '@shared/config';
import { useProductActionsContext } from '@shared/contexts/ProductActionsContext';
import { useSearchQuery } from '@shared/hooks';

import MarketplaceMenuWrapper from './MarketplaceMenuWrapper';
import { NAVIGATION_ITEMS } from './constants';

const MarketplaceNavigation = () => {
  const pathname = usePathname();
  const { triggerModal } = useDepositModal();

  const { searchQuery, setSearchQuery, handleSearch } = useSearchQuery();

  const { data: userInfo, isPending } = useCurrentUser();

  const { productToApprove, productToSetVisibility } =
    useProductActionsContext();

  const shouldDisplayApproveOptions =
    productToApprove && pathname === `/products/${productToApprove.id}`;

  const shouldDisplayVisibilityOptions =
    productToSetVisibility &&
    pathname === `/products/${productToSetVisibility.id}`;

  const shouldDisplayReviewOptions =
    shouldDisplayApproveOptions || shouldDisplayVisibilityOptions;

  const shouldShowMaintenanceToast = config.maintenanceModeWarning;

  return (
    <>
      <Banners />
      <div
        className={twMerge(
          'sticky top-0 z-99 border-b border-t border-neutral700 bg-neutral1000'
        )}
      >
        <nav
          className={twMerge(
            'relative top-0 z-99 m-auto flex h-16 w-full max-w-[1750px] items-center justify-end gap-4 md:justify-between lg:pl-4'
          )}
        >
          <a
            href={'/'}
            className="flexCenter ml-3 max-w-40 shrink-0 text-neutral300 max-xl:flex-[2] xl:h-10"
          >
            <LogoSvg
              width={160}
              height={40}
              className={twMerge('size-full overflow-visible')}
            />
          </a>
          <div className="flex size-full items-center justify-end gap-4 max-xl:flex-1 md:justify-between">
            {shouldDisplayReviewOptions ? (
              <>
                {shouldDisplayApproveOptions && (
                  <SubmissionNavActions product={productToApprove} />
                )}
                {shouldDisplayVisibilityOptions && (
                  <ProductNavActions product={productToSetVisibility} />
                )}
              </>
            ) : (
              <>
                <Search
                  placeholder={'Search AI products by name or publisher'}
                  className="order-1 min-w-28 flex-1 max-md:hidden"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  noMobile
                  onSearch={handleSearch}
                />
                <MarketplaceMenuWrapper
                  items={NAVIGATION_ITEMS}
                  className="flex-2 order-3 lg:px-0"
                  breakpoint="xl"
                  isLoggedIn={!!userInfo?.data}
                />
              </>
            )}
            <div className="order-1 flex h-full items-center xl:order-3">
              {<BuyAitechButton className="mr-2.5 max-xl:hidden" />}
              {userInfo?.data && (
                <button
                  onClick={() => triggerModal()}
                  className="animatedBorderButton label-small roundedBlTr md:flexCenter relative z-[1] mr-6 hidden h-10 w-fit shrink-0 cursor-pointer overflow-hidden px-3 font-bold text-white max-xl:!hidden"
                >
                  Deposit aitech
                </button>
              )}

              {userInfo?.data && <NotificationsButton />}

              <ConnectButton
                className="order-1 flex h-full items-center md:order-3"
                userInfo={userInfo?.data}
                isPending={isPending}
                innerClassName="md:pr-0 xl:pr-4"
              />
            </div>
          </div>
        </nav>
        {shouldShowMaintenanceToast && <ToastMaintenance disabledClose />}
      </div>
    </>
  );
};

export default MarketplaceNavigation;
