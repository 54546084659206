import useCreateDepositMutation from '@api/user/nowPayments/mutations/useCreateDepositMutation';

import { toastError } from '@shared/utils';

import { BUY_AITECH_STEP } from '../../constants';
import BaseCurrencyStep from './base';
import { IBuyStepProps } from './types';

const BuyWithCryptoStep = ({
  setCurrentStep,
  setIsLoading,
  setNowPaymentsData,
  ...rest
}: IBuyStepProps) => {
  const { mutateAsync: createDeposit, isPending } = useCreateDepositMutation({
    onSuccess: ({ data }) => {
      setCurrentStep(BUY_AITECH_STEP.SUCCESS_CRYPTO);
      setIsLoading(false);
      setNowPaymentsData({
        address: data.toAddress,
        qrCode: data.qrCodeDataUrl,
      });
    },
    onError: (error) => {
      setIsLoading(false);
      toastError(error.message);
    },
  });

  const onBack = () => {
    setCurrentStep(BUY_AITECH_STEP.CRYPTO_LIST);
  };

  return (
    <BaseCurrencyStep
      {...rest}
      setIsLoading={setIsLoading}
      buyMutation={createDeposit}
      isBuying={isPending}
      onBack={onBack}
    />
  );
};
export default BuyWithCryptoStep;
