import { ReactElement, cloneElement } from 'react';

import { ISvgImport } from '@interfaces';
import { twMerge } from 'tailwind-merge';

import Modal from '../Modal';
import ModalFooter from '../ModalFooter';
import { IDefaultModalProps, IModalFooterProps } from '../types';

interface IProps extends IDefaultModalProps, IModalFooterProps {
  Icon: ISvgImport;
  iconClassName?: string;
  title: string | ReactElement;
  description?: string;
}

const BasicModal = ({
  isModalOpen,
  setIsModalOpen,
  Icon,
  iconClassName,
  description,
  title,
  cancelButtonProps,
  confirmButtonProps,
  hideDivider,
  onClose,
}: IProps) => {
  // If the text needs to be something other than just a text
  const isTitleString = typeof title === 'string';

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      className="!max-w-25.5rem"
      onClose={onClose}
    >
      <div className="space-y-3 sm:space-y-6">
        <div className="flexCenter roundedBlTr size-20 border border-neutral700 bg-neutral900">
          {cloneElement((<Icon />) as ReactElement, {
            className: twMerge('size-6 text-green500', iconClassName),
          })}
        </div>
        {isTitleString ? <p className="font-xxlarge">{title}</p> : <title />}
        {!!description && (
          <p className="font-large text-neutral200">{description}</p>
        )}
      </div>
      <ModalFooter
        cancelButtonProps={cancelButtonProps}
        confirmButtonProps={confirmButtonProps}
        hideDivider={hideDivider}
      />
    </Modal>
  );
};

export default BasicModal;
