'use client';

import { ChangeEvent, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { SearchIcon } from '@shared/assets';

import DesktopSearch from './DesktopSearch';
import MobileSearchInput from './MobileSearch';
import { IProps, IndividualSearchProps } from './types';

const Search = ({
  onSearch,
  onChange,
  placeholder = 'Search',
  className,
  value,
  noMobile,
}: IProps) => {
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
  };

  const props: IndividualSearchProps = {
    onChange: handleSearchChange,
    onSearch,
    placeholder,
    isSearchInputFocused,
    setIsSearchInputFocused,
    searchIcon: SearchIcon,
    value,
  };

  return (
    <>
      <MobileSearchInput
        {...props}
        className={twMerge('md:hidden', noMobile && 'hidden', className)}
      />
      <DesktopSearch
        {...props}
        className={twMerge(
          'hidden md:-mb-px md:flex',
          noMobile && 'flex',
          className
        )}
      />
    </>
  );
};

export default Search;
