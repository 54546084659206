import Image from 'next/image';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import useGetCryptoCurrenciesQuery from '@api/user/nowPayments/queries/useGetCryptoCurrenciesQuery';

import BuyAitechListItem from '@components/buyAitech/buyAitechListItem';
import { BuyAitechListItemOption } from '@components/buyAitech/buyAitechListItem/types';
import { Loading } from '@components/loading';
import ModalFooter from '@components/modals/ModalFooter';
import Search from '@components/search';

import { CloseCircle } from '@shared/assets';
import { NOW_PAYMENTS_BASE_URL } from '@shared/constants';

import {
  BUY_AITECH_STEP,
  BUY_AITECH_STEP_TYPE,
  MAX_SEARCH_LENGTH,
} from '../../constants';

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<BUY_AITECH_STEP_TYPE>>;
  setCurrencyItem: (currency: BuyAitechListItemOption) => void;
  currencyItem: BuyAitechListItemOption;
}

const CryptoListStep = ({
  setCurrentStep,
  setCurrencyItem,
  currencyItem,
}: IProps) => {
  const [searchInput, setSearchInput] = useState('');
  const { data, isLoading } = useGetCryptoCurrenciesQuery();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    if (input.length <= MAX_SEARCH_LENGTH) {
      setSearchInput(input);
    }
  };

  const mappedTokens = useMemo(() => {
    return data?.data.currencies.map((item) => ({
      value: item.code,
      label: `${item.name} / ${item.code}`,
      icon: (
        <div className="relative h-6 w-6">
          <Image
            src={`${NOW_PAYMENTS_BASE_URL}${item.logoUrl}`}
            fill
            alt={item.name}
          />
        </div>
      ),
      network: item.network,
    }));
  }, [data?.data]);

  const filteredTokens = mappedTokens?.length
    ? mappedTokens?.filter(({ value, label }) => {
        const searchableValues = [label.toLowerCase(), value.toLowerCase()];

        return searchableValues.some((searchableValue) =>
          searchableValue.includes(searchInput.toLowerCase())
        );
      })
    : mappedTokens;

  const onNext = () => {
    setCurrentStep(BUY_AITECH_STEP.BUY_CRYPTO);
  };

  const onBack = () => {
    setCurrencyItem(undefined);
    setCurrentStep(BUY_AITECH_STEP.PAYMENT_METHODS);
  };

  return (
    <div>
      <p className="font-medium font-normal text-neutral200">
        Please select 1 specific currency to continue the progress
      </p>

      <div>
        <Search
          value={searchInput}
          onChange={handleSearchChange}
          placeholder="Enter crypto name"
          className="!my-6 w-full overflow-hidden rounded-bl rounded-tr border [&>input]:appearance-none"
          noMobile
        />
        <div>
          <p className="mb-3 mt-1 font-medium font-normal text-neutral200">
            Select token
          </p>
          <div className="green-scrollbar max-h-17.25rem overflow-y-auto pr-1">
            {isLoading && <Loading className="mt-0" />}

            {!isLoading &&
              filteredTokens?.map((option, index) => (
                <BuyAitechListItem
                  key={index}
                  setSelectedItem={setCurrencyItem}
                  isSelected={currencyItem?.value === option.value}
                  option={option}
                  icon={option.icon}
                />
              ))}

            {!isLoading && !filteredTokens?.length && (
              <div className="flex flex-col items-center gap-1.5 border border-neutral700 p-3">
                <CloseCircle className="h-4 w-4 text-neutral400" />
                <p className="font-small text-neutral200">
                  No matches were found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalFooter
        cancelButtonProps={{
          text: 'Back',
          onClick: onBack,
        }}
        confirmButtonProps={{
          text: 'Next',
          onClick: onNext,
          isDisabled: !currencyItem,
        }}
      />
    </div>
  );
};
export default CryptoListStep;
