import { Dispatch, SetStateAction } from 'react';

import { DepositByFiatParams } from '@interfaces/buyAitech/depositByFiat';
import { useMutation } from '@tanstack/react-query';

import {
  BUY_AITECH_STEP,
  BUY_AITECH_STEP_TYPE,
} from '@features/marketplace/buyAitech/constants';

import { toastError } from '@shared/utils';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useCreateDepositByFiatMutation = (
  setCurrentStep: Dispatch<SetStateAction<BUY_AITECH_STEP_TYPE>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  return useMutation({
    mutationFn: async (body: DepositByFiatParams) =>
      nowPaymentsApi.client.createDepositByFiat(body),
    onSuccess: () =>
      // responseData
      {
        setCurrentStep(BUY_AITECH_STEP.COMPLETE_PAYMENT_FIAT);
        setIsLoading(false);
        //TODO dodat redirect vjv nesto ovako
        // window.location.href = responseData.data.invoiceUrl;
      },
    onError: (error) => {
      setIsLoading(false);
      toastError(error.message);
    },
  });
};

export default useCreateDepositByFiatMutation;
