import {
  CryptoCurrenciesResponse,
  FiatCurrenciesResponse,
} from '@interfaces/buyAitech';
import { IDepositBody, IDepositResponse } from '@interfaces/buyAitech/deposit';
import {
  DepositByFiatParams,
  DepositByFiatResponse,
} from '@interfaces/buyAitech/depositByFiat';
import {
  EstimateParams,
  EstimateResponse,
} from '@interfaces/buyAitech/estimate';
import {
  MinAmountParams,
  MinAmountResponse,
} from '@interfaces/buyAitech/minAmount';

import { withAuthenticatedClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';
import { generateUrlParams } from '@shared/utils/urlString';

const getFiatCurrencies =
  (request: ICallableRequestBuilder<FiatCurrenciesResponse>) => async () => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/now-payment/fiat-currencies`
    );
  };

const getCryptoCurrencies =
  (request: ICallableRequestBuilder<CryptoCurrenciesResponse>) => async () => {
    return request.call(
      `${config.userApiUrl}/api/v1/user/now-payment/currencies`
    );
  };

const getEstimateRate =
  (request: ICallableRequestBuilder<EstimateResponse>) =>
  async (params?: EstimateParams) => {
    const paramString = generateUrlParams(params);
    return request.call(
      `${config.userApiUrl}/api/v1/user/now-payment/estimate?${paramString}`
    );
  };

const getMinAmount =
  (request: ICallableRequestBuilder<MinAmountResponse>) =>
  async (params?: MinAmountParams) => {
    const paramString = generateUrlParams(params);
    return request.call(
      `${config.userApiUrl}/api/v1/user/now-payment/min-amount?${paramString}`
    );
  };

const createDeposit =
  (request: ICallableRequestBuilder<IDepositResponse>) =>
  async (body: IDepositBody) => {
    const url = `${config.userApiUrl}/api/v1/user/now-payment/deposit`;

    return await request.call(url, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }));
  };

const createDepositByFiat =
  (request: ICallableRequestBuilder<DepositByFiatResponse>) =>
  async (body: DepositByFiatParams) => {
    const url = `${config.userApiUrl}/api/v1/user/now-payment/deposit-by-fiat`;

    return await request.call(url, (init) => ({
      ...init,
      method: 'POST',
      headers: {
        ...init.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }));
  };

export const nowPaymentsApi = {
  client: {
    getFiatCurrencies: withAuthenticatedClientRequest(getFiatCurrencies),
    getCryptoCurrencies: withAuthenticatedClientRequest(getCryptoCurrencies),
    getEstimateRate: withAuthenticatedClientRequest(getEstimateRate),
    getMinAmount: withAuthenticatedClientRequest(getMinAmount),
    createDeposit: withAuthenticatedClientRequest(createDeposit),
    createDepositByFiat: withAuthenticatedClientRequest(createDepositByFiat),
  },
};
