import * as Yup from 'yup';

import { INPUT_LIMITS } from './constants';

export const buyAitechSchema = Yup.object().shape({
  amount: Yup.string()
    .test('numFormat', 'Invalid number format', (value) => {
      if (!value) return true;

      const [integerPart, decimalPart] = value.split('.');

      if (integerPart.length > INPUT_LIMITS.maxInteger) {
        return false; // Integer part exceeds limit
      }

      if (decimalPart && decimalPart.length > INPUT_LIMITS.maxDecimal) {
        return false; // Decimal part exceeds limit
      }

      return true;
    })
    .test('noTrailingDot', 'No trailing dot allowed', (value) => {
      if (!value) return true;

      return !value.endsWith('.');
    }),
});

export type BuyAitechFormData = Yup.InferType<typeof buyAitechSchema>;
