import useCreateDepositByFiatMutation from '@api/user/nowPayments/mutations/useCreateDepositByFiatMutation';

import { BUY_AITECH_STEP } from '../../constants';
import BaseCurrencyStep from './base';
import { IBuyStepProps } from './types';

const BuyWithFiatStep = ({
  setCurrentStep,
  setIsLoading,
  ...rest
}: IBuyStepProps) => {
  const { mutateAsync: createDepositByFiat, isPending } =
    useCreateDepositByFiatMutation(setCurrentStep, setIsLoading);

  const onBack = () => {
    setCurrentStep(BUY_AITECH_STEP.FIAT_LIST);
  };

  return (
    <BaseCurrencyStep
      {...rest}
      setIsLoading={setIsLoading}
      buyMutation={createDepositByFiat}
      isBuying={isPending}
      onBack={onBack}
    />
  );
};

export default BuyWithFiatStep;
