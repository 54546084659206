import Image from 'next/image';
import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { BANNERS } from './constants';

const Banners = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  const handleAnimationEnd = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % BANNERS.length);
    setIsSliding(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsSliding(true);
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="relative flex w-full items-center justify-center overflow-hidden p-4 md:p-6"
      data-banners-height
    >
      <div className="relative aspect-[97/9] h-full max-h-[90px] w-full max-w-[970px]">
        {BANNERS.map((banner, index) => (
          <a
            key={banner.src}
            href={banner.href}
            target="_blank"
            rel="noopener noreferrer"
            className={twMerge(
              'roundedBlTr absolute inset-0 overflow-hidden transition-opacity duration-500 ease-in-out',
              index === currentIndex ? 'z-10 opacity-100' : 'z-0 opacity-0',
              isSliding && index === currentIndex && 'animate-slideOutBanner',
              isSliding &&
                index === (currentIndex + 1) % BANNERS.length &&
                'animate-slideInBanner'
            )}
            onAnimationEnd={
              isSliding && index === currentIndex
                ? handleAnimationEnd
                : undefined
            }
          >
            <Image
              src={banner.src}
              alt={`Banner ${index + 1}`}
              className="object-contain"
              fill
              sizes="(max-width: 970px) 100vw, 970px"
              priority={index === currentIndex}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Banners;
