export type BUY_AITECH_STEP_TYPE = { id: string; title?: string };

export const BUY_AITECH_STEP = {
  PAYMENT_METHODS: { id: 'payment_methods', title: 'Payment methods' },
  FIAT_LIST: { id: 'currency_list', title: 'Currency list' },
  CRYPTO_LIST: { id: 'crypto_list', title: 'Crypto list' },
  BUY_FIAT: { id: 'buy_fiat', title: 'Buy AI TECH' },
  BUY_CRYPTO: { id: 'buy_crypto', title: 'Buy AI TECH' },
  SUCCESS_CRYPTO: { id: 'success_crypto' },
  COMPLETE_PAYMENT_FIAT: {
    id: 'complete_payment_fiat',
    title: 'Complete payment',
  },
};

export const INPUT_LIMITS = {
  maxDecimal: 8,
  maxInteger: 10,
};

export const CURRENCY_AITECH = 'aitech';

export const CURRENCY_USDT = 'usdttrc20';

// AM-1179
export const MAX_SEARCH_LENGTH = 100;
