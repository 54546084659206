import { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { RadioCheckedIcon, RadioIcon } from '@shared/assets';

import { BuyAitechListItemOption } from './types';

interface IProps {
  setSelectedItem: (item: BuyAitechListItemOption) => void;
  isSelected: boolean;
  option: BuyAitechListItemOption;
  icon?: ReactNode;
}

const BuyAitechListItem = ({
  setSelectedItem,
  isSelected,
  option,
  icon,
}: IProps) => {
  const Icon = icon || option?.icon;

  return (
    <button
      onClick={() =>
        setSelectedItem && setSelectedItem(isSelected ? undefined : option)
      }
      onKeyUp={(e) => {
        e.preventDefault();
      }}
      className={twMerge(
        'align-self-stretch font-large flex w-full cursor-pointer flex-col items-start gap-4 text-wrap border p-3 text-left text-neutral200 [&:not(:last-child)]:mb-2',
        isSelected ? 'border-green500' : 'border-neutral700'
      )}
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex items-center gap-3">
          {option?.icon || icon ? (
            <div
              className={twMerge(
                !icon &&
                  'roundedBrTl border border-neutral700 p-2.5 text-neutral300'
              )}
            >
              {Icon}
            </div>
          ) : null}
          <div className="flex flex-col gap-1">
            <p className="whitespace-break-spaces text-wrap text-white">
              {option?.label}
            </p>
            {!!option?.network && (
              <p className="font-medium text-neutral300">
                {option?.network.toUpperCase()}
              </p>
            )}
          </div>
        </div>
        {isSelected ? (
          <RadioCheckedIcon className="shrink-0" />
        ) : (
          <RadioIcon className="shrink-0" />
        )}
      </div>
    </button>
  );
};
export default BuyAitechListItem;
