import { useQuery } from '@tanstack/react-query';

import { CRYPTO_CURRENCIES } from '@shared/constants/queryKeys';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useGetCryptoCurrenciesQuery = () =>
  useQuery({
    queryKey: [CRYPTO_CURRENCIES],
    queryFn: () => nowPaymentsApi.client.getCryptoCurrencies(),
  });

export default useGetCryptoCurrenciesQuery;
