import { twMerge } from 'tailwind-merge';

import CopyIcons from '@components/copyIcons';

import { useCopyText } from '@shared/hooks';
import { shortenString } from '@shared/utils';

interface IProps {
  firstPlaceholder: string;
  firstValue?: string;
  secondPlaceholder: string;
  secondValue?: string;
  secondSubValue?: string;
  thirdPlaceholder?: string;
  thirdValue?: string;
}

const TransactionDetails = ({
  firstPlaceholder,
  firstValue,
  secondPlaceholder,
  secondValue,
  secondSubValue,
  thirdPlaceholder,
  thirdValue,
}: IProps) => {
  const { isCopied, copyAddressHandler } = useCopyText(thirdValue as string);

  return (
    <div className="flex flex-col">
      {firstValue && (
        <div className="flex w-full items-start justify-between gap-4 border border-b-0 border-neutral700 p-2">
          <span className="!font-normal font-medium">{firstPlaceholder}:</span>
          <span className="ml-auto text-end !font-normal font-medium uppercase">
            {firstValue}
          </span>
        </div>
      )}

      {secondValue && (
        <div
          className={twMerge(
            'flex w-full items-start justify-between gap-4 border border-neutral700 p-2',
            thirdValue && 'border-b-0'
          )}
        >
          <span className="!font-normal font-medium">{secondPlaceholder}:</span>
          <div className="flex flex-col !font-normal font-medium">
            <span className="mb-2 ml-auto text-end">{secondValue}</span>
            <span className="ml-auto text-end"> {secondSubValue}</span>
          </div>
        </div>
      )}

      {thirdValue && (
        <div className="flex w-full items-start justify-between gap-4 border border-neutral700 p-2">
          <span className="!font-normal font-medium">{thirdPlaceholder}:</span>
          <div className="flex gap-2 font-medium">
            <span
              className={twMerge(
                '!font-normal',
                isCopied ? 'text-green500' : 'text-white'
              )}
            >
              {shortenString(thirdValue)}
            </span>

            <CopyIcons
              className="size-4"
              isCopied={isCopied}
              copyAddressHandler={copyAddressHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
