import { MinAmountParams } from '@interfaces/buyAitech/minAmount';
import { useQuery } from '@tanstack/react-query';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useGetMinimumAmount = (params: MinAmountParams) =>
  useQuery({
    queryKey: [params],
    queryFn: () => nowPaymentsApi.client.getMinAmount(params),
  });

export default useGetMinimumAmount;
