import { EstimateParams } from '@interfaces';
import { useQuery } from '@tanstack/react-query';

import { nowPaymentsApi } from '../nowPaymentsApi';

const useGetEstimateRate = ({
  params,
  currency,
}: {
  params: EstimateParams;
  currency: 'aitech' | 'usdttrc20';
}) =>
  useQuery({
    queryKey: [params, currency],
    queryFn: () =>
      nowPaymentsApi.client.getEstimateRate({
        ...params,
        currencyTo: currency,
      }),
    refetchInterval: !!params.amount && !isNaN(+params.amount) ? 40000 : false,
    enabled: !!params.amount,
  });

export default useGetEstimateRate;
