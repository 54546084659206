import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

import useGetFiatCurrenciesQuery from '@api/user/nowPayments/queries/useGetFiatCurrenciesQuery';

import Button, { BUTTON_VARIANT } from '@components/button';
import BuyAitechListItem from '@components/buyAitech/buyAitechListItem';
import { BuyAitechListItemOption } from '@components/buyAitech/buyAitechListItem/types';
import { Loading } from '@components/loading';
import Search from '@components/search';

import { CloseCircle } from '@shared/assets';

import {
  BUY_AITECH_STEP,
  BUY_AITECH_STEP_TYPE,
  MAX_SEARCH_LENGTH,
} from '../../constants';

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<BUY_AITECH_STEP_TYPE>>;
  setCurrencyItem: (currency: BuyAitechListItemOption) => void;
  currencyItem: BuyAitechListItemOption;
}

const FiatCurrencyListStep = ({
  setCurrentStep,
  setCurrencyItem,
  currencyItem,
}: IProps) => {
  const [searchInput, setSearchInput] = useState('');
  const { data, isLoading } = useGetFiatCurrenciesQuery();

  const mappedCurrencies = useMemo(() => {
    return data?.data.fiats.map((item) => ({
      value: item.currencyCode,
      label: item.currencyCode,
      icon: (
        <ReactCountryFlag
          svg
          style={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
          countryCode={item.currencyCode.slice(0, 2)}
        />
      ),
    }));
  }, [data?.data]);

  const filteredCurrencies = mappedCurrencies?.length
    ? mappedCurrencies?.filter((currency) =>
        currency.value.toLowerCase().includes(searchInput.toLowerCase())
      )
    : mappedCurrencies;

  const onNext = () => {
    setCurrentStep(BUY_AITECH_STEP.BUY_FIAT);
  };

  const onBack = () => {
    setCurrencyItem(undefined);
    setCurrentStep(BUY_AITECH_STEP.PAYMENT_METHODS);
  };

  return (
    <div>
      <p className="font-medium font-normal text-neutral200">
        Please select 1 specific currency to continue the progress
      </p>

      <div>
        <Search
          value={searchInput}
          onChange={({ target: { value } }) => {
            if (value.length > MAX_SEARCH_LENGTH) return;

            setSearchInput(value);
          }}
          placeholder="Enter currency name"
          className="!my-6 w-full overflow-hidden rounded-bl rounded-tr border-t"
          noMobile
        />
        <div>
          <p className="mb-3 mt-1 font-medium font-normal text-neutral200">
            Select currency
          </p>
          <div className="green-scrollbar max-h-17.25rem overflow-y-auto pr-1">
            {isLoading && <Loading className="mt-0" />}

            {!isLoading &&
              filteredCurrencies?.map((option) => (
                <BuyAitechListItem
                  key={option.value}
                  setSelectedItem={setCurrencyItem}
                  isSelected={currencyItem?.value === option.value}
                  option={option}
                  icon={option.icon}
                />
              ))}

            {!isLoading && !filteredCurrencies?.length && (
              <div className="flex flex-col items-center gap-1.5 border border-neutral700 p-3">
                <CloseCircle className="h-4 w-4 text-neutral400" />
                <p className="font-small text-neutral200">
                  No matches were found
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr className="my-6 border-neutral700" />
      <div className="flex justify-between">
        <Button variant={BUTTON_VARIANT.SECONDARY} onClick={onBack}>
          Back
        </Button>
        <Button disabled={!currencyItem} onClick={onNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default FiatCurrencyListStep;
