import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';

import { yupResolver } from '@hookform/resolvers/yup';

import { useWeb3Modal } from '@web3modal/wagmi/react';

import useGetEstimateRate from '@api/user/nowPayments/queries/useGetEstimateRate';
import useGetEstimateRates from '@api/user/nowPayments/queries/useGetEstimateRates';
import useGetMinimumAmount from '@api/user/nowPayments/queries/useGetMinimumAmount';

import CurrencyLabel from '@components/currencyLabel';
import HookFormInput from '@components/form/hookFormInput';
import LoadingIcon from '@components/loading/LoadingIcon';
import ModalFooter from '@components/modals/ModalFooter';
import BasicModal from '@components/modals/basicModal';

import {
  CURRENCY_AITECH,
  INPUT_LIMITS,
} from '@features/marketplace/buyAitech/constants';
import { formatFlexibleDecimals } from '@features/utils/numbers';

import { WarningIcon } from '@shared/assets';
import { CryptoCurrencyID } from '@shared/constants';
import { useDebounce, useForumAuthStatus } from '@shared/hooks';
import { toastWarning } from '@shared/utils';

import { BuyAitechFormData, buyAitechSchema } from '../../../buyAitech.schema';
import { IBaseBuyStepProps } from '../types';

interface BuyMutationArgs {
  payAmount: number;
  payCurrency: string;
}

export interface IProps extends IBaseBuyStepProps {
  isBuying: boolean;
  onBack: () => void;
  buyMutation: (body: BuyMutationArgs) => void;
}

const BaseCurrencyStep = ({
  currencyCode,
  setCurrencyAmounts,
  setIsLoading,
  buyMutation,
  onBack,
  isBuying,
}: IProps) => {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [currencyAmount, setCurrencyAmount] = useState('');
  const [error, setError] = useState('');

  const { isLoggedIn } = useForumAuthStatus();
  const { open } = useWeb3Modal();

  const methods = useForm<BuyAitechFormData>({
    mode: 'onChange',
    resolver: yupResolver(buyAitechSchema),
  });

  const { setValue, register } = methods;

  const { data: minAmount } = useGetMinimumAmount({
    currencyFrom: currencyCode,
    currencyTo: CURRENCY_AITECH,
  });

  const minAmountValue = minAmount?.data.minAmount || 0;

  const { data: estimateRateForOne } = useGetEstimateRate({
    params: {
      amount: '1',
      currencyFrom: currencyCode,
    },
    currency: CURRENCY_AITECH,
  });

  const {
    data: estimates,
    isFetching: isLoadingEstimates,
    isError: isEstimateError,
  } = useGetEstimateRates(
    {
      amount: currencyAmount,
      currencyFrom: currencyCode,
    },
    +currencyAmount > minAmountValue
  );

  const debounceOnChange = useDebounce(async (value) => {
    setCurrencyAmount(value);
  }, 1000);

  const body = {
    payAmount: Number(currencyAmount),
    payCurrency: currencyCode,
  };

  const onAgreeCryptoBuy = () => {
    setIsWarningModalOpen(false);
    if (!isLoggedIn) {
      open();

      return toastWarning('Not logged in', {
        description: 'Please log in to proceed',
      });
    }

    setIsLoading(true);

    setCurrencyAmounts({
      youPayAmount: currencyAmount,
      youGetAmount: estimates?.aitechEstimate.toString() || '0',
      usdaiAmount: estimates?.usdaiEstimate.toString() || '0',
    });

    buyMutation(body);
  };

  const onNumberChange = (
    { floatValue, value }: NumberFormatValues,
    errorMessage?: string
  ) => {
    if (errorMessage) {
      setError(errorMessage);
      return;
    } else {
      setError('');
    }

    setValue('amount', value, { shouldValidate: true });
    floatValue && debounceOnChange(floatValue.toString());
  };

  const outputEstimatePrice = (estimatedPrice?: number | string) => {
    if (isEstimateError || !Number(currencyAmount)) {
      return 0;
    }

    return formatFlexibleDecimals(estimatedPrice || 0);
  };

  return (
    <>
      <FormProvider {...methods}>
        <HookFormInput
          id="amount"
          label="Please input the amount to continue the progress "
          register={register('amount')}
          placeholder="0"
          hideIsOptional
          innerContainerClassName="flex justify-between w-full flex-wrap"
          error={error}
          type="number"
          numberInputProps={{
            onNumberChange,
            maxIntegerLength: INPUT_LIMITS.maxInteger,
            maxDecimalCount: INPUT_LIMITS.maxDecimal,
            thousandSeparator: true,
          }}
        >
          <CurrencyLabel currency={currencyCode} />
          <div className="font-xsmall mt-2 w-full space-y-1">
            <p className="text-neutral200">
              *1% fee applied for Nowpayment gateway
            </p>
            <p className="text-neutral200">
              {`*Estimated rate: 1 ${currencyCode}`}{' '}
              <span className="relative top-1">&tilde;</span>
              {` ${estimateRateForOne?.data.estimatedAmount || 0} ${CryptoCurrencyID.AITECH}`}
            </p>
            <p className="text-neutral200">
              *Minimum payment amount: {minAmount?.data.minAmount}{' '}
              {currencyCode}
            </p>
            <p className="text-red500">*Gas fee not included</p>
          </div>
        </HookFormInput>
        <div className="mt-6 bg-neutral700 px-2.5 py-3">
          <p className="label-small mb-3 text-neutral200">You get</p>
          <div className="font-xsmall flex w-full justify-between font-bold">
            <p className="bg-neutral800 px-1.5 py-1">
              {isLoadingEstimates ? (
                <LoadingIcon width={20} height={15} />
              ) : (
                outputEstimatePrice(estimates?.aitechEstimate)
              )}
            </p>
            <p className="bg-neutral800 px-1.5 py-1 text-neutral200">
              {CryptoCurrencyID.AITECH}
            </p>
          </div>
        </div>
        <div className="mt-2 bg-neutral700 p-4">
          <div className="label-small flex w-full justify-between font-bold">
            <p className="text-neutral200">Estimated USDAI Earning:</p>
            <p className="flex">
              {isLoadingEstimates ? (
                <LoadingIcon width={20} height={15} />
              ) : (
                outputEstimatePrice(estimates?.usdaiEstimate)
              )}{' '}
              {CryptoCurrencyID.USDAI}
            </p>
          </div>
        </div>
        <ModalFooter
          cancelButtonProps={{
            text: 'Back',
            onClick: onBack,
          }}
          confirmButtonProps={{
            text: 'Buy',
            onClick: () => setIsWarningModalOpen(true),
            isDisabled:
              !!error ||
              !Number(currencyAmount) ||
              isLoadingEstimates ||
              isBuying ||
              isEstimateError,
          }}
        />
      </FormProvider>
      <BasicModal
        Icon={WarningIcon}
        title="Disclaimer"
        description="Please be aware that making a transaction with an amount below the minimum threshold carries significant risk. You may not be able to request a refund and receive corresponding AITECH tokens as well. Our system will not be responsible if your tokens are withheld due to transfer an amount below the minimum required."
        isModalOpen={isWarningModalOpen}
        setIsModalOpen={setIsWarningModalOpen}
        iconClassName="text-yellow500"
        cancelButtonProps={{
          text: 'Back',
          onClick: () => setIsWarningModalOpen(false),
        }}
        confirmButtonProps={{
          text: 'Agree',
          onClick: onAgreeCryptoBuy,
        }}
      />
    </>
  );
};
export default BaseCurrencyStep;
