import GrantProgram from '@assets/images/1m-grant-b.jpg';
import ComputeMarketplace from '@assets/images/compute-marketplace-coming-soon.jpg';
import SambanovaMarketplace from '@assets/images/sambanova-marketplace.jpg';

export const BANNERS = [
  {
    src: GrantProgram.src,
    href: 'https://grant.aitech.io/',
  },
  {
    src: ComputeMarketplace.src,
    href: 'https://gpu.aitech.io/',
  },
  {
    src: SambanovaMarketplace.src,
    href: 'https://sambanova.ai/',
  },
];
