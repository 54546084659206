'use client';

import { Dispatch, SetStateAction, useState } from 'react';

import Modal from '@components/modals/Modal';

import BuyAitechStep from './BuyAitechStep';
import { BUY_AITECH_STEP, BUY_AITECH_STEP_TYPE } from './constants';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const BuyAitechModal = ({ isOpen, setIsOpen }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<BUY_AITECH_STEP_TYPE>(
    BUY_AITECH_STEP.PAYMENT_METHODS
  );

  const isSuccessStep = currentStep.id === BUY_AITECH_STEP.SUCCESS_CRYPTO.id;

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalTitle={currentStep.title}
      disableClose={isLoading}
      className={isSuccessStep ? '!max-w-25.5rem' : '!max-w-42.25rem'}
      modalContentClassName="green-scrollbar"
    >
      <BuyAitechStep
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setIsLoading={setIsLoading}
        handleClose={() => setIsOpen(false)}
      />
    </Modal>
  );
};

export default BuyAitechModal;
