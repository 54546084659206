import { useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { CloseIcon, InfoIcon } from '@shared/assets';

interface IProps {
  disabledClose?: boolean;
}

const ToastMaintenance = ({ disabledClose }: IProps) => {
  //U slucaju da budu trazili da se moze maknut poruka
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div
      className={twMerge(
        'absolute left-[50%] mt-4 w-full max-w-[634px] -translate-x-[50%] px-4',
        !disabledClose && 'max-w-[664px]',
        isHidden && 'hidden'
      )}
    >
      <div className="roundedBlTr text-yellow400 flex gap-2 rounded-none border-l-0 bg-yellow900 p-3 max-md:items-start">
        <InfoIcon className="h-3.5 w-3.5 shrink-0 self-center text-yellow500" />
        <p className="font-medium">
          Notice : The AI Marketplace will be under maintenance from 11:00pm UTC
          to 2am UTC.
        </p>
        {!disabledClose && (
          <button onClick={() => setIsHidden(true)} className="ml-auto">
            <CloseIcon className="size-3 text-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ToastMaintenance;
