import { useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import RemoveBoxedIcon from '@components/removeBoxedIcon';

import { IndividualSearchProps } from './types';

const MobileSearch = ({
  className,
  onSearch,
  onChange,
  placeholder,
  isSearchInputFocused,
  setIsSearchInputFocused,
  searchIcon: SearchIcon,
  value,
}: IndividualSearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const inputValue = !!onSearch
    ? {
        value,
      }
    : {
        defaultValue: value,
      };

  return (
    <div
      data-testid="search"
      className={twMerge(
        'nav-bar-icon md:size-full md:bg-none',
        isSearchInputFocused && 'text-white',
        className
      )}
    >
      <button
        onClick={() => setIsSearchInputFocused(true)}
        className="flexCenter size-full"
      >
        <SearchIcon className="size-6 transform md:absolute md:right-4 md:top-1/2 md:-translate-y-1/2" />
      </button>

      <div
        className={twMerge(
          'fixed z-10 flex h-16 w-screen items-center border-b border-neutral300 transition-all duration-300',
          isSearchInputFocused ? 'left-0 opacity-100' : 'left-full opacity-0'
        )}
      >
        <div className="transitionColor relative size-full flex-1">
          <input
            type="search"
            autoComplete="off"
            id="search"
            {...inputValue}
            className="size-full bg-neutral900 pl-3 pr-12 font-medium text-white placeholder-neutral400 caret-brandPrimary outline-none ring-0"
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSearch?.();
              }
            }}
            ref={inputRef}
          />
          <SearchIcon
            className="absolute right-3 top-1/2 size-6 -translate-y-1/2 text-white"
            onClick={onSearch}
          />
        </div>
        <RemoveBoxedIcon
          onClick={() => setIsSearchInputFocused(false)}
          className="border-r-0 border-neutral300"
        />
      </div>
    </div>
  );
};

export default MobileSearch;
